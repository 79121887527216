$toolbar-height: 60px;
$background-color: whitesmoke;

.App {
  text-align: center;

  height: 100%;
  width: 100%;
  // background-image: url('./styles/images/background.jpg');
  // background-position: 0;
  // background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root {
  height: 100vh;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-link {
  color: #61dafb;
}

.scroll-panel {
  margin-top: calc($toolbar-height + 5px) !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  width: 100%;
  overflow: auto;
  font-size: 16px;
  background: rgb(216, 215, 227);
  background: linear-gradient(
    180deg,
    rgba(216, 215, 227, 1) 0%,
    rgba(184, 183, 183, 1) 43%,
    rgba(113, 112, 111, 1) 100%
  );
  //margin-top: $toolbar-height;
}

.app-container {
  height: calc(100% - $toolbar-height);
  top: $toolbar-height;
  position: fixed;
  width: 100%;
  border: 1px solid gray;
}

.app-header {
  height: $toolbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensures the header stays on top of other elements */
  background-color: #fff; /* Optional: Set a background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */

  margin-top: 0px;

  .p-toolbar {
    background-color: #f8edb9;
    padding: 0px;
    padding-left: 5px;
    border-bottom: 1px solid black;
  }

  .header-logo {
    margin-left: 20px;
  }

  // .p-breadcrumb {
  //   background: transparent;
  //   border: none;
  //   border-radius: 3px;
  //   padding: 5px;
  // }

  // .p-breadcrumb-list {
  //   height: 24px;
  // }
}

.section {
  text-align: center;
  background-color: $background-color;
}

.section-container {
  background-color: $background-color;
}

.section-paragraph {
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 6px #888888;
  
}

.app-feature {
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 6px #888888;
  
  p {
    padding: 5x;
  }
}

.section-header {
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 6px #888888;
  margin-left: 10px;
  width: calc(100% - 20px) !important;
  
}

.section-image {
  border: 1px solid black;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 6px #888888;
  padding: 5px;
}

.section-wide-image-lg {
  padding: 10px;
  max-width: 90%;
  margin: 5px;
}

.section-image-lg {
  width: 280px;
  height: auto;
}

.section-carousel {
  margin: 10px;
  padding: 50px;
}

.app-screen-image {
  width: 150px;
}


h1 {
  font-size: 32px;
}

.flow-card {
  // border: 1px solid black;
  // border-radius: 10px;
}

/* For laptops */
@media (max-width: 1024px) {
  .section-image-lg {
    width: 240px;
    height: auto;
  }

}

/* For tablets */
@media (max-width: 768px) {
  .section-image-lg {
    width: 180px;
    height: auto;
  }


}

/* For cell phones */
@media (max-width: 480px) {
  .section-image-lg {
    width: 120px;
    height: auto;
  }

}


